import React from 'react'
import Layout from '../../../components/layout'
import { Container, Grid, Box, Typography } from '@material-ui/core'

const PayError: React.FunctionComponent = props => {
  return (
    <Layout pageTitle={'We had a Problem'}>
      <Container>
        <Grid
          style={{ height: '100vh' }}
          container
          spacing={8}
          justify={'space-around'}
          alignItems={'center'}
        >
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <Box boxShadow={2}>
              <Box padding={1} bgcolor={'primary.main'}>
                <Typography variant={'h4'} style={{ color: 'white' }}>
                  What Happens Next ?
                </Typography>
              </Box>
              <Box padding={2}>
                <Typography>
                  We hate it when things go wrong, and we know you do to. The
                  team will look into what happened and get back to you with a
                  fix
                </Typography>
                <Typography>Don't worry, you have not been charged!</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default PayError
